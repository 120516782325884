






























import Vue from 'vue';
import Layout from "@/router/layouts/auth.vue";
import {mapActions, mapGetters} from "vuex";
import { required, email } from "vuelidate/lib/validators";

export default Vue.extend({
  components: {Layout},

  data() {
    return {
      email: "",
      isSubmitted: false,
    }
  },

  //@ts-ignore
  validations: {
    email: {required, email}
  },

  computed: {
    ...mapGetters("emailsModule", {
      loader: "GET_LOADER"
    })
  },

  methods: {
    ...mapActions("emailsModule", {
      sendResetPasswordEmail: "SEND_RESET_PASSWORD_EMAIL"
    }),
    async resetPassword(): Promise<void> {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      await this.sendResetPasswordEmail({ email: this.email, locale: this.$i18n.locale });
      this.isSubmitted = false;
      this.email = "";
    }
  }
});
